import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import logo from "../../../assets/images/blog/zielabi/ZielABI-wordmark.png"
import pdf from "../../../assets/images/blog/zielabi/ZielABI-presentation-final-30102017-min.pdf"
import fr from "../../../assets/images/blog/zielabi/zielabi-frankfurt-logo.png"
import app from "../../../assets/images/blog/zielabi/zielabi-app-logo.png"
import app2 from "../../../assets/images/blog/zielabi/zielabi-app-logos.png"
import phone1 from "../../../assets/images/blog/zielabi/fd1.png"
import phone2 from "../../../assets/images/blog/zielabi/fd2.png"
import phone3 from "../../../assets/images/blog/zielabi/fd3.png"
import phone4 from "../../../assets/images/blog/zielabi/fd4.png"
import phone5 from "../../../assets/images/blog/zielabi/fd5.png"
import phone6 from "../../../assets/images/blog/zielabi/fd6.png"
import phone7 from "../../../assets/images/blog/zielabi/fd7.png"
import phone8 from "../../../assets/images/blog/zielabi/fd8.png"
import phone9 from "../../../assets/images/blog/zielabi/fd9.png"
import phone10 from "../../../assets/images/blog/zielabi/fd10.png"
import phone11 from "../../../assets/images/blog/zielabi/fd11.png"
import phone12 from "../../../assets/images/blog/zielabi/fd12.png"
import bubble from "../../../assets/images/blog/zielabi/zielabi-ios-bubble.png"
import bubble2 from "../../../assets/images/blog/zielabi/zielabi-android-bubble.png"
import website from "../../../assets/images/blog/zielabi/zielabi-website.png"


export default () => 
<div>
    <SEO title={'ZielABI App Development - Case Study | ICON Worldwide'} 
    description="Looking for an interesting app development process? Download ZielABI's app development case study on ICON's blog!"
    canonical={'https://icon-worldwide.com/blog/zielabi-app-development-case-study'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1>Frankfurt School of Finance & Management – <span>Mobile iOS & Android App Development</span> [Case Study]</h1>
        <div id="title-underline"></div>
        <div className="table">
            <div className="row">
                <img src={logo} className="logo" alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
                <h2>App Development Case Study</h2>
                <button><a  href={pdf} target="_blank" rel="noopener noreferrer">Download Case Study</a></button>
                <h3 className="down">Creative Brief</h3>
            </div>

            <div className="row">
                <div className="two-columns">
                    <img src={fr} className="logo" alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
                </div>
                <div className="two-columns">
                    <h2>The First Abitur Calculator App for iOS & Android</h2>
                    <p>We were tasked with creating an app that helped calculate students’. Abitur scores so that they could easily
                    see what grades they needed to get into the University of their choice, no matter where in Germany they live.</p>
                </div>
                <h3 className="down">Design and Logo</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                    <img src={app2} alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
                    <h2>Logo Design</h2>
                    <p>Working to visualize the business in a motivational manner, the “A” from Abitur is excuted in a compass style icon.
                        A list within implies data collection.</p>
                </div>
                <div className="three-columns">
                    <img src={app} alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
                    <h2>Final Logo</h2>
                    <p>The final ZielABI logo uses just two colors to make an impact. This inverted design helps it to stand out among the other apps on a users’s screen.</p>
                </div>
                <div className="three-columns">
                    <h2>ZielABI Identity Design</h2>
                    <p>ZielABI is designed to empower students to achieve their goals by simplifying the stressful and complicated Abitur calculation.</p>
                    <p>The typography and colors are deliberately warm and friendly.</p>
                    <p>The logo is motivational in spirit.</p>
                    <p>The tagline: Deine Zunkunft im Ziel “Your future on target” is presumptive.</p>
                </div>
                <h3 className="down">Final Designs</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                <img src={phone1} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>The design for this screen retains much of the initial design concept, with some extra refinement.</p>
                </div>
                <div className="three-columns">
                <img src={phone2} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>A simple yes/no question needs no complication.</p>
                </div>
                <div className="three-columns">
                <img src={phone3} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Much of this screen is carried over from the initial design, with a simplified interface for categorizing classes.</p>
                </div>
                <h3 className="down">Final Designs</h3>
            </div>


            <div className="row">
                <div className="three-columns">
                <img src={phone4} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Preserving much of the initial design, this A pop-up shows the student when they Students can compare various scenarios,
                    page adds greater legibility to semester have reached their goal. with different goals and class grades. grades and brings
                    the progress bar to the bottom of the screen.</p>
                </div>
                <div className="three-columns">
                <img src={phone5} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>A pop-up shows the student when they Students can compare various scenarios, page adds greater legibility to semester have reached their goal.</p>
                </div>
                <div className="three-columns">
                <img src={phone6} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Students can compare various scenarios, page adds greater legibility to semester have reached their goal. with different goals and class grades.</p>
                </div>
                <h3 className="down">Target Abitur Score & Course Selection</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                <img src={phone7} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>ZielABI revolves around students setting their initial Abitur goal. The visual target, and slide selector, make this step easy to complete.</p>
                </div>
                <div className="three-columns">
                <img src={phone8} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>The goal with the initial wireframing was to keep things simple. The complicated formulas fade away and the sudent only sees a few simple questions.</p>
                </div>
                <div className="three-columns">
                <img src={phone9} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Students can tap multiple times to select options to differentiate between types of courses.</p>
                </div>
                <h3 className="down">Grade Input and Saving Scenarios</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                <img src={phone10} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Abitur calcuation requires a large amount of data input by students which is managed via a combination of easy tap, slide, and scroll motions.</p>
                </div>
                <div className="three-columns">
                <img src={phone11} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>As students enter their grades, the Abitur score is updated below. When they reach their target a pop-up congratulations message appears.</p>
                </div>
                <div className="three-columns">
                <img src={phone12} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <p>Saving scenarios allows students to return to the app and adjust their score as grades become actual.</p>
                </div>
                <h3 className="down">iOS Development</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                    <h2>Swift</h2>
                    <p>Using the latest version of Apple’s design language, we were able to implement powerful formulas behind a simple interface and create an app that could easily be translated in multiple languages.</p>
                </div>
                <div className="three-columns">
                    <h2>Custom Libraries</h2>
                    <p>We used highly customisable open source libraries to speed up the development process while ensuring that elements of the app stayed true to our design.</p>
                    <p>By testing on beta versions of the next major iOS release we ensured the app worked as expected with the release of iOS 11.</p>
                </div>
                <div className="three-columns">
                    <img src={bubble} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <h2>iOS Bubble Design</h2>
                    <p>Inspiration for the design of the class selector ‘bubbles’ on the app came from design features in Apple’s latest release of iOS.</p>
                </div>
                <h3 className="down">Android Development</h3>
            </div>

            <div className="row">
                <div className="three-columns">
                    <h2>Java</h2>
                    <p>By coding natively in Java we were able to develop an efficient code base for the Android app.</p>
                    <p>Through the implementation of standard code libraries we are able to insure compadibility across a whole range of mobile devices.</p>
                </div>
                <div className="three-columns">
                    <h2>Custom Libraries</h2>
                    <p>In using the most modern and powerful tools and frameworks we achieved effectiveness in the development process. The custom layouts and animations make the application more attractive and user friendly.</p>
                    <p>We also included different analytics and crashlytics libraries to track and investigate the app’s behavior and results.</p>
                </div>
                <div className="three-columns">
                    <img src={bubble2} alt="app development case study, Frankfurt School, ICON Worldwide Blog" title="ICON Worldwide blog, app development case study, Frankfurt School"/> 
                    <h2>Android Bubble Design</h2>
                    <p>Android keeps the ‘bubbles’ from the iOS design, but organizes them, instead, in a simple Android-inspired grid.</p>
                </div>
                <h3 className="down">Final Release</h3>
            </div>

            <div className="row">
                <div className="two-columns">
                    <img src={website} className="website-img" alt="ICON Worldwide logo" title="ICON Worldwide logo"/> 
                </div>
                <div className="two-columns">
                    <h2>Website</h2>
                    <p>The design of the website draws elements from the app and includes animated mockups of the ZielABI app.</p>
                    <p>Included in the site is more information about the app and how it works with a different set of calculations for each German state.</p>
                    <p>We created it with simple HTML, CSS, and JavaScript.</p>
                    <a  href="http://ziel-abi.de/">ziel-abi.de</a>
                </div>
            </div>

        </div>
        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

        <NewsFooter previous="sams-app-development-case-study" next="icon-open-ai-chat"/>
    </div>

    <Footer noScrollbar="true"/>
</div>